/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import * as UserAction from "../../../Action/AdminDashboard";
import CustomerList from "./CustomerList";
import TabMenu from "./TabMenu";
import "./style.scss";
import { isEmpty } from "lodash";
import {
  SET_INVISIBLE_MULTIPLE_COMPANY_SELECTION,
  SET_INVISIBLE_SELECTION,
  SET_VISIBLE_MULTIPLE_COMPANY_SELECTION,
  SET_VISIBLE_SELECTION,
} from "../../../utils/AdminDashboard/Constant";

const CustomerManagement = (props) => {
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const [customer, setCustomer] = useState(null);
  const [isProfileTabActive, setIsProfileTabActive] = useState(false);
  const [page, setPage] = useState(1);

  const filterselection = [
    { id: "ALL", value: "All" },
    { id: "UPCOMING", value: "Upcoming Customer" },
  ];

  const [filters, setFilters] = useState({
    filter: filterselection[0].id,
    companyIds: [],
    filterByName: null,
  });

  const multipleCompanies = useSelector(
    (state) => state.hotelReducer.multipleCompanies
  );

  const customers = useSelector((state) => state.hotelReducer.customers);
  const chunkRisk = useSelector((state) => state.hotelReducer.chunkRisk);

  const handleChangeFilter = (event) => {
    const field = event.target.name;
    let commonData = { ...filters };
    commonData[field] = event.target.value;
    return setFilters(commonData);
  };

  useEffect(() => {
    dispatch({
      type: SET_VISIBLE_MULTIPLE_COMPANY_SELECTION,
    });
    dispatch({
      type: SET_INVISIBLE_SELECTION,
    });
    return () => {
      dispatch({
        type: SET_INVISIBLE_MULTIPLE_COMPANY_SELECTION,
      });
      dispatch({
        type: SET_VISIBLE_SELECTION,
      });
      setIsProfileTabActive(false);
    };
  }, []);

  const fetchCustomersAction = (page, filters, customerId = null) => {
    if (filters.companyIds.length > 0) {
      props.actions.userAction.getAllCustomer(
        {
          ...filters,
          page,
        },
        customerId
      );
    }
  };

  const fetchCustomers = useCallback(() => {
    fetchCustomersAction(page, filters, customerId || null);
  }, [page, filters]);

  const prevCompanyIdsRef = useRef(filters.companyIds);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (filters.companyIds.length > 0) {
      const haveCompanyIdsChanged =
        prevCompanyIdsRef.current !== filters.companyIds;

      if (haveCompanyIdsChanged) {
        prevCompanyIdsRef.current = filters.companyIds;

        // Only reset the page if it's not already 1
        if (page !== 1) {
          setPage(1);
        } else {
          // If page is already 1, fetch customers immediately
          fetchCustomers();
        }
      }
    }
  }, [filters.companyIds]);

  useEffect(() => {
    if (!isFirstRender.current && filters.companyIds.length > 0) {
      fetchCustomers();
    }
  }, [page]);

  useEffect(() => {
    if (multipleCompanies && multipleCompanies.length > 0) {
      const mappedCompany = multipleCompanies.filter(
        (company) => company.isChecked === true && company.name !== "All"
      );

      setFilters({ ...filters, companyIds: mappedCompany });
    }
  }, [multipleCompanies]);

  useEffect(() => {
    if (customers) {
      const { data: customer } = customers;
      let selectedCustomer = null;
      if (!isEmpty(customer)) {
        if (customerId) {
          const findCustomer = customer.find(
            (customer) => customer.id === Number(customerId)
          );
          if (findCustomer) selectedCustomer = findCustomer;
          else selectedCustomer = customer[0];
          setIsProfileTabActive(true);
        } else if (isProfileTabActive) selectedCustomer = customer[0];
        else selectedCustomer = customer[0];
        getCustomerReservation(selectedCustomer);
        setCustomer(selectedCustomer);
      }
    }
  }, [customers]);

  const getCustomerReservation = (customer) => {
    if (customer)
      props.actions.userAction.getCustomerProfile(
        customer.id,
        filters.companyIds
      );
  };

  const handleChangeCustomer = (data) => {
    setCustomer(data);
    getCustomerReservation(data);
  };

  const handleProfileActive = (value) => {
    value ? setIsProfileTabActive(value) : setIsProfileTabActive(value);
  };

  const filterByName = (name) => {
    setFilters((prev) => {
      const update = { ...prev, filterByName: name };
      setPage(1);
      fetchCustomersAction(1, update, null);
      return update;
    });
  };

  return (
    <div className="user-groups">
      <h1 className="groups-header">Customer Management</h1>
      <div className="customer-management">
        <div className="management-left">
          <CustomerList
            customers={customers && customers.data ? [...customers.data] : []}
            selectedCustomer={{ ...customer }}
            loadMoreData={() =>
              setPage((prev) =>
                customers && customers.totalPages && prev < customers.totalPages
                  ? prev + 1
                  : prev
              )
            }
            filerByName={filterByName}
            handleChangeCustomer={handleChangeCustomer}
          />
        </div>
        <div className="management-right">
          {!isEmpty(customer) && (
            <TabMenu
              customer={customer}
              filterselection={filterselection}
              chunkRisk={chunkRisk}
              handleChangeFilter={handleChangeFilter}
              filters={filters}
              handleProfileActive={handleProfileActive}
              isProfileActive={customerId ? true : false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    userAction: bindActionCreators(UserAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(CustomerManagement);
